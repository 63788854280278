<template>
    <div class = "searchBridges">
        <div class = "google-map" id="map"></div>
        <div class = "mobileView" v-if="this.mobileView && this.NoError">
            <h3>BRIDGE MAPS</h3>
            <h4>Search by SN</h4>
            <p>Find a bridge nationwide by searching <b><u>Structure Number</u></b>.</p>
            <input v-on:keyup.enter="findBridgeSelected()" placeholder="Enter Structure Number" v-model="searchedSN">
            <br/><button class="btn" v-on:click="findBridgeSelected()" v-if="!this.loading">FIND BRIDGE</button>
            <div v-if="this.loading" class="loader"></div>
            <br/>
            <p class="notes">example 0223001</p>
        </div>
        <div class="btmRight" v-if="this.mobileView">
            <button class = "aboutButtonMobile" v-on:click="openAboutUsPage()"><img src="../assets/about_icon.png" /></button>
            <button class = "terrainSatelliteMobile" v-on:click="terrainSatelliteChange()"><img src="@/assets/layers.png" /></button>
        </div>
        <div class="MobileDetailView" v-if="this.mobileView && (this.found)">
                <div v-if="this.found">
                  <h4>{{this.bridgeName}} {{this.bridgeCross}}</h4>
                  <h5>{{this.bridgeSN}}</h5>
                  <h5>{{this.lat}},{{this.lng}}</h5>
                  <br/><br/><br/>
                  <button class="actionBTN" v-on:click="openGoogelLink()">DIRECTIONS</button>
                  <button class="actionBTN" v-on:click="openMicroData()">ALL DATA</button>
                  <button class="actionBTN2" v-on:click="openNewWindow('mailto:info@cercledesigns.com')">REPORT ERROR</button>
                </div>
        </div>

        <div class = "topRight" v-if="this.desktopView">
          <h2>BRIDGE MAPS</h2>
          <button class = "terrainSatellite" v-on:click="terrainSatelliteChange()" ><img src="@/assets/layers.png" /></button>
          <button class = "aboutButton" v-on:click="openAboutUsPage()" style="margin-left: 10px"><img src="../assets/about_icon.png" /></button>
        </div>

        <div class="TopLeft">
            <div class = "searchTopLeft" v-if="this.desktopView && this.NoError">
              <h2>SEARCH BY S.N.</h2>
              <p>Find a bridge nationwide by searching <b>Structure Number</b>.</p>
              <input v-on:keyup.enter="findBridgeSelected()" placeholder="Enter Structure Number" v-model="searchedSN">
              <p class="notes">example 0223001</p>
              <br/>
              <button class="btn" v-on:click="findBridgeSelected()" v-if="!this.loading">FIND BRIDGE</button>
              <div v-if="this.loading" class="loader"></div>
            </div>
            <br>
            <div v-if="this.found">
                <div class = "miniDetailsLeft" v-if="this.desktopView">
                    <div v-if="this.found">
                    <h2>{{this.bridgeName}}</h2>
                    <h2>{{this.bridgeCross}}</h2>
                    <h4>SN: {{this.bridgeSN}}</h4>
                    <h5>Lat, Long: {{this.lat}},{{this.lng}}</h5>
                    <button class="btn" v-on:click="openGoogelLink()">DIRECTIONS</button>
                    <button class="btn" v-on:click="openMicroData()">ALL DATA</button>
                    <button class="btn_two" v-on:click="openErrorDiv()" style="margin-left: 10px">REPORT ERROR</button>
                    </div>
                </div>
            </div>
            <br>
            <div class="ErrorDivBtmLft" v-if="this.errorBtnPrsd">
                <h2>REPORT ERROR</h2>
                <p>To report an error, please send us as many details as possible (include screenshots) to <a href="mailto:info@cercledesigns.com">info@cercledesigns.com</a></p>
            </div>
            <div class="ErrorPrompt" v-if="!this.NoError">
                <h1>ERROR</h1>
                <p>An error occured while trying to find your bridge, please check the Structure Number used and try again.</p>
                <button class="btn" v-on:click="resetSearch()">RESET SEARCH</button>
            </div>
        </div>
                    
        <div v-if="this.aboutPrompt" class="selectionModal2">
            <button class = "ModalCloseBtn" v-on:click="closeButtonSelected()">X</button>
            <h2>HOW IT'S MADE</h2>
            <p>Bridge Maps allows the user to find their bridge Nationwide. The maps are connected to the United States Department of Transportation (USDOT) Bureau of Transportation Statistics' National Bridge Inventory (NBI). The link is done via an open source API done by the USDOT NBI, <a v-on:click="openNewWindow('https://data-usdot.opendata.arcgis.com/datasets/national-bridge-inventory')"><b><u>read more about the API.</u></b></a></p>
            <br/>
            <h2>ABOUT DEVELOPER</h2>
            <p>Cercle Designs, LLC has developed multuple bridge related apps including Bridge Inspect and Bridge Info</p>
            <button class="btn2" v-on:click="openNewWindow('http://cercledesigns.com')">DEVELOPER SITE</button>
            <button class="btn2" v-on:click="openNewWindow('mailto:info@cercledesigns.com')">CONTACT US</button>
            <br/><br/><br/>
            <p>Icons made by <a href="https://www.flaticon.com/authors/kiranshastry" title="Kiranshastry">Kiranshastry</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a></p>
        </div>
        
      </div>
  </template>
  
  <script> 
  import axios, { AxiosError } from 'axios'
  
  export default {
    name: 'SearchView',
      components:{
  
      },
      data(){
          return{
              desktopView: true,
              mobileView: false,
              errorBtnPrsd: false,
              i: 0,
              googleMapsLink: '',
              bridgeName: '',
              bridgeSN: '',
              NoError: true,
              aboutPrompt: false,
              tempIndex: 0,
              tempData: [''],
              County: "",
              searchedSN: '',
              splittedState: "",
              splittedCounty: "",
              refNum: "",
              StateBtnFormat :'btn',
              CountyBtnFormat :'btn',
              SelectCountyTitle : 'SelectCounty',
              SelectStateTitle : "SelectState",
              StateUrl: "",
              CountyUrl: "",
              searchstarted: true,
              stateSelected : false,
              countySelected : false,
              showStateSelector: false,
              showCountySelector: false,
              selectedIndex: null,
              found: false,
              loading: false,
              selectedState: null,
              fltrShow: false,
              lat: 41.8869222,
              lng: -88.15970833,
              Counties: null,
              tempURL: null,
              bridgeCross: null,
              tempSN: null,
              mapType:'terrain',//to make it a satellite, you will need to do map.setMapTypeId('satellite') when selecting the option. Or define this as a variable item to be changed.
              mapTypeImage:"@/assets/satellite.png" 
          }
      },
      methods:{
          renderMap(){
                  const map = new google.maps.Map(document.getElementById('map'), {
                      center: {lat: this.lat ,lng: this.lng},
                      zoom: 17,
                      mapTypeId: this.mapType, 
                      maxZoom: 50,
                      minZoom: 3,
                      panControl:false,
                      zoomControl:false,
                      mapTypeControl:false,
                      scaleControl:false,
                      streetViewControl:false,
                      overviewMapControl:false,
                      rotateControl:false,
                      fullscreenControl:false,
                      styles:[{"elementType": "geometry","stylers": [
                          {
                              "color": "#f5f5f5"
                          }
                      ]
                      },
                      {
                          "elementType": "labels.icon",
                          "stylers": [
                          {
                              "visibility": "off"
                          }
                          ]
                      },
                      {
                          "elementType": "labels.text.fill",
                          "stylers": [
                          {
                              "color": "#616161"
                          }
                          ]
                      },
                      {
                          "elementType": "labels.text.stroke",
                          "stylers": [
                          {
                              "color": "#f5f5f5"
                          }
                          ]
                      },
                      {
                          "featureType": "administrative.land_parcel",
                          "elementType": "labels",
                          "stylers": [
                          {
                              "visibility": "off"
                          }
                          ]
                      },
                      {
                          "featureType": "administrative.land_parcel",
                          "elementType": "labels.text.fill",
                          "stylers": [
                          {
                              "color": "#bdbdbd"
                          }
                          ]
                      },
                      {
                          "featureType": "poi",
                          "elementType": "geometry",
                          "stylers": [
                          {
                              "color": "#eeeeee"
                          }
                          ]
                      },
                      {
                          "featureType": "poi",
                          "elementType": "labels.text",
                          "stylers": [
                          {
                              "visibility": "off"
                          }
                          ]
                      },
                      {
                          "featureType": "poi",
                          "elementType": "labels.text.fill",
                          "stylers": [
                          {
                              "color": "#757575"
                          }
                          ]
                      },
                      {
                          "featureType": "poi.park",
                          "elementType": "geometry",
                          "stylers": [
                          {
                              "color": "#33d9b2"
                          },
                          {
                              "saturation": -30
                          },
                          {
                              "lightness": 55
                          }
                          ]
                      },
                      {
                          "featureType": "poi.park",
                          "elementType": "labels.text",
                          "stylers": [
                          {
                              "color": "#218c74"
                          },
                          {
                              "saturation": 100
                          },
                          {
                              "visibility": "on"
                          },
                          {
                              "weight": 0.5
                          }
                          ]
                      },
                      {
                          "featureType": "road",
                          "elementType": "geometry",
                          "stylers": [
                          {
                              "color": "#ffffff"
                          }
                          ]
                      },
                      {
                          "featureType": "road.arterial",
                          "elementType": "labels.text.fill",
                          "stylers": [
                          {
                              "color": "#757575"
                          },
                          {
                              "visibility": "on"
                          }
                          ]
                      },
                      {
                          "featureType": "road.highway",
                          "elementType": "geometry",
                          "stylers": [
                          {
                              "color": "#dadada"
                          }
                          ]
                      },
                      {
                          "featureType": "road.highway",
                          "elementType": "labels.text.fill",
                          "stylers": [
                          {
                              "color": "#616161"
                          }
                          ]
                      },
                      {
                          "featureType": "road.local",
                          "elementType": "labels",
                          "stylers": [
                          {
                              "visibility": "off"
                          }
                          ]
                      },
                      {
                          "featureType": "road.local",
                          "elementType": "labels.text.fill",
                          "stylers": [
                          {
                              "color": "#9e9e9e"
                          },
                          {
                              "visibility": "on"
                          }
                          ]
                      },
                      {
                          "featureType": "transit.line",
                          "elementType": "geometry",
                          "stylers": [
                          {
                              "color": "#e5e5e5"
                          }
                          ]
                      },
                      {
                          "featureType": "transit.station",
                          "elementType": "geometry",
                          "stylers": [
                          {
                              "color": "#eeeeee"
                          }
                          ]
                      },
                      {
                          "featureType": "water",
                          "elementType": "geometry",
                          "stylers": [
                          {
                              "color": "#34ace0"
                          },
                          {
                              "saturation": -45
                          },
                          {
                              "lightness": 25
                          },
                          {
                              "weight": 2
                          }
                          ]
                      },
                      {
                          "featureType": "water",
                          "elementType": "labels.text.fill",
                          "stylers": [
                          {
                              "color": "#227093"
                          },
                          {
                              "weight": 8
                          }
                          ]
                      }]
                  })
                  let marker = new google.maps.Marker({
                      position:{
                          lat: this.lat,
                          lng: this.lng
                      },
                      map
                  })
              },
  
          learnMore(){
              this.show = !this.show
          },
          showAllData(){
              this.$router.push({name:'MicroData', params:{structure_number: this.searchedSN}})
          },
          openNewWindow(url){
              window.open(url, "_blank")
          },
          closeButtonSelected(){
              this.searchstarted = true
              this.errorPromptBool = false
              this.aboutPrompt = false
          },

          resetSearch(){
            this.NoError = true
          },

          findBridgeSelected(){
              this.found = false
              this.loading = true
              //Check the length of the input
                if (this.searchedSN.length<15){
                    //length of input is less than 15 chars, add "0"s at beginning and at end and search for both items
                    //ADD 0s before text then search
                    this.tempSN = this.searchedSN
                    while(this.tempSN.length<15){
                        this.tempSN = this.tempSN + "0"
                    }                
                    //ADD "0"s after text then search
                    this.tempURL = 'https://geo.dot.gov/server/rest/services/Hosted/National_Bridge_Inventory_DS/FeatureServer/0/query?where=structure_number_008%20%3D%20'+"'"+this.tempSN+"'"+'&outFields=*&outSR=4326&f=json'
                    axios.get(this.tempURL).then(response=>{
                    //THE ERROR IS HERE
                    if (response.data.features.length>0){
                    
                    this.tempData = JSON.parse(JSON.stringify(response.data.features[0].attributes))
                        //Found data
                        this.lng = response.data.features[0].geometry.x
                        this.lat = response.data.features[0].geometry.y
                        this.renderMap()
                        this.loading = false
                        this.found = true
                        this.bridgeName = this.tempData.facility_carried_007 + ' over'
                        this.bridgeCross = this.tempData.features_desc_006a
                        this.bridgeSN = this.tempData.structure_number_008
                    } else{
                    
                        //Did not find data and so search with 0's at the beginning of the searched item
                        this.tempSN = this.searchedSN
                        while(this.tempSN.length<15){
                            this.tempSN = "0" + this.tempSN
                        }
                        this.tempURL = 'https://geo.dot.gov/server/rest/services/NTAD/National_Bridge_Inventory/MapServer/0/query?where=STRUCTURE_NUMBER_008%20%3D%20%27'+this.tempSN+'%27&outFields=*&outSR=4326&f=json'
                        axios.get(this.tempURL).then(response=>{                        
                        if (response.data.features) {
                            this.tempData = response.data.features[0].attributes
                            
                            if (response.data.features.length>0){
                                this.lng = response.data.features[0].geometry.x
                                this.lat = response.data.features[0].geometry.x
                                this.renderMap()
                                this.loading = false
                                this.found = true
                                this.bridgeName = this.tempData.facility_carried_007
                                this.bridgeCross = 'over ' + this.tempData.features_desc_006A
                                this.bridgeSN = this.tempData.structure_number_008
                            } else {
                                //Could not find bridge show error
                                this.errorPromptBool = true
                                //Error - Could not find bridge
                            }
                        } else {
                            //Error - Could not find bridge
                            //Turn off sections (Desktop version)
                            this.loading = false
                            this.found = false
                            this.NoError = false

                        }
                        
                        })
                    }
                })
                } else {
                  //Chars are equal to or more than 15 chars, get data directly
                  //JQuery Link example to get specific items
                  this.tempURL = 'https://geo.dot.gov/server/rest/services/NTAD/National_Bridge_Inventory/MapServer/0/query?where=STRUCTURE_NUMBER_008%20%3D%20%27'+this.searchedSN+'%27&outFields=*&outSR=4326&f=json'
                  axios.get(this.tempURL).then(response=>{
                      this.tempData = response.data.features[0].attributes
                      if (response.data.features.length>0){
                          //Got the data
                          //Move to location of bridge
                          this.lng = response.data.features[0].geometry.x
                          this.lat = response.data.features[0].geometry.y
                          this.renderMap()
                          this.loading = false
                          this.found = true
                          this.bridgeName = this.tempData.facility_carried_007
                          this.bridgeCross = 'over ' + this.tempData.features_desc_006A
                          this.bridgeSN = this.tempData.structure_number_008
                      }else{
                          //add an error message
                          this.errorPromptBool = true
                      }
                  })
                }
          },
          openGoogelLink(){
            this.googleMapsLink = 'https://www.google.com/maps/dir/?api=1&destination=' + this.lat + ',' + this.lng
            window.open(this.googleMapsLink, "_blank")
          },
          openMicroData(){
            this.$router.push({name:'DetailView', params:{sn: this.tempSN}})
          },
          openAboutUsPage(){
            this.aboutPrompt = true
          },
          openErrorDiv(){
            this.errorBtnPrsd = true
          },
          terrainSatelliteChange(){
              if (this.mapType == "satellite") {
                  this.mapType = "terrain"
              } else {
                  this.mapType = "satellite"
              }            
              this.renderMap()
          },
          recenterMap(){
            //Function to simply recenter the map after the user had moved away from it
          },
          handleView(){
              if (window.innerWidth <= 700) {
                  this.mobileView = true
                  this.desktopView = false
              } else {
                  this.mobileView = false
                  this.desktopView = true
              }
          }
      },
      mounted(){
          //get the user geolocation
          /*if(navigator.geolocation){
              navigator.geolocation.getCurrentPosition(pos =>{
                  this.lat = pos.coords.latitude
                  this.lng = pos.coords.longitude
                  this.renderMap()
              }, (err) =>{
                  this.renderMap()
              }, { maximumAge: 60000, timeout: 3000})
          } else {
              //position center by default values
              this.renderMap()
          }*/
          this.renderMap()
      },
      created(){
          this.handleView();
          window.addEventListener('resize', this.handleView);
      }
  }
  </script>
  
  <style scoped>
  
  </style>