<template>
  <div class="selectionModal3">
    <h1>404</h1>
    <h3>Oops! This Page Could Not be Found</h3>
    <p>Sorry but the page you are looking for does not exist or could not be found.</p>
    <p>Please click below to go to our home page.</p>
    <button class="btn" v-on:click="goToSearchPage()">HomePage</button>
  </div>
</template>

<script>
export default {
    name:'NotFound',
    data(){

    },
    methods:{
        goToSearchPage() {
            this.$router.push({name:'SearchSN'})
        }
    }
}
</script>

<style scoped>
.selectionModal3{
    background: black;
    padding: 2%;
    width: 50%;
    border-radius: 20px;
    color: white;
    position: absolute;
    left: 22.5%;
    top: 22.5%;
}

.selectionModal3 p{
    color: rgb(218, 215, 215);
}
</style>