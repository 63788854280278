import { createRouter, createWebHistory } from 'vue-router'
import SearchView from '../views/SearchView.vue'
import DetailView from '../views/MicroInfo.vue'
import NotFoundPage from '../views/NotFound.vue'
import '@/components/Global.css'

const routes = [
  {
    path: '/',
    name: 'SearchSN',
    component: SearchView
  },
  {
    path: '/detailview/:sn',
    name: 'DetailView',
    component: DetailView
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFoundPage',
    component: NotFoundPage
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
