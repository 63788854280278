<template>
    <div class="MicroData" v-if="this.found">
        <div v-if="this.notAboutPrompt">
            <button class = "aboutButton" v-on:click="openAboutUsPage()"><img src="../assets/about_icon.png" /></button>
            <h2>{{this.$route.params.sn}} DATA</h2>  
            <h4>{{this.bridgeName}} over {{this.bridgeCross}}</h4>
            <br/>
            <button class="actionBTN" v-on:click="goToSearchPage()">NEW SEARCH</button>
            <button class="actionBTN2" v-on:click="openNewWindow('mailto:info@cercledesigns.com')">REPORT ERROR</button>
            <br/><br/><hr/>
            
            <br/>
            <h6>STRUCTURE MAP</h6>
            <div id="map" class="google-map2" v-if="this.found"></div>
            <br/>        
            <button class="btn2" v-on:click="openGoogelLink()">DIRECTIONS</button>
            <button class="btn2" v-on:click="terrainSatelliteChange()">CHANGE TERRAIN</button>

            <br/><br/>
            <h6>STRUCTURE GENERAL INFO</h6>
            <div class="subRows" v-for:="Sub_Title in this.Sub_Titles1">
                <p>{{Sub_Title.name}}</p>
                <h3>{{Sub_Title.value}}</h3>
            </div>
            
            <br/>
            <h6>LATEST INSPECTION DATA</h6>
            <div class="subRows" v-for:="Sub_Title in this.Sub_Titles2">
                <p>{{Sub_Title.name}}</p>
                <h3>{{Sub_Title.value}}</h3>
            </div>
            <br/><br/>
            
            <button class="btn_long" v-on:click="showHideAllData()">{{this.dataButtonTitle}}</button>
            <div v-if="this.showAllData">
                <h6>LATEST INSPECTION DATA</h6>
                <div class="subRows" v-for:="Sub_Title in this.AllDataTitles">
                    <p>{{Sub_Title.name}}</p>
                    <h3>{{Sub_Title.value}}</h3>
                </div>
            </div>
            <br/>
            <h6>DISCLAIMER</h6>
            <p>The data points are collected directly from the United States Department of Transportation (USDOT) Bureau of Transportation Statistics' National Bridge Inventory (NBI).</p>
        </div>
        <transition name = "fade" appear v-if="this.aboutPrompt">
          <div class="selectionModal2">
            <button class = "ModalCloseBtn" v-on:click="closeButtonSelected()">X</button>
            <h2>HOW IT'S MADE</h2>
            <p>Bridge Maps allows the user to find their bridge Nationwide. The maps are connected to the United States Department of Transportation (USDOT) Bureau of Transportation Statistics' National Bridge Inventory (NBI). The link is done via an open source API done by the USDOT NBI, <a v-on:click="openNewWindow('https://data-usdot.opendata.arcgis.com/datasets/national-bridge-inventory')"><b><u>read more about the API.</u></b></a></p>
            <br/>
            <h2>ABOUT DEVELOPER</h2>
            <p>Cercle Designs, LLC has developed multuple bridge related apps including Bridge Inspect and Bridge Info</p>
            <button class="btn2" v-on:click="openNewWindow('http://cercledesigns.com')">DEVELOPER SITE</button>
            <button class="btn2" v-on:click="openNewWindow('mailto:info@cercledesigns.com')">CONTACT US</button>
            <br/><br/><br/>
            <p>Icons made by <a href="https://www.flaticon.com/authors/kiranshastry" title="Kiranshastry">Kiranshastry</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a></p>
          </div>
      </transition>
    </div>

    <div class="center" v-if="!this.found && !this.loading">
        <h2>BRIDGE MAPS</h2>
        <div class="ErrorPrompt">            
            <h1>ERROR</h1>
            <p>An error occured while trying to find your bridge, please check the Structure Number used and try again.</p>
            <button class="btn" v-on:click="goToSearchPage()">FIND THE STRUCTURE</button>
        </div>
    </div>
    
    <div class="center" v-if="this.loading">
        <h2>BRIDGE MAPS</h2>
        <h4>Loading Data...</h4>
    </div>

</template>

<script>
import axios, { AxiosError } from 'axios'

export default {
    name: 'MicroData',
    components:{

    },
    data(){
        return{
            ReconstrYr: "N/A",
            dataButtonTitle: "MORE DATA",
            mapType: "satellite",
            showAllData: false,
            found: false,
            bridgeName: '',
            bridgeCross: '',
            lat: 41.8869222,
            lng: -88.15970833,
            aboutPrompt: false,
            notAboutPrompt: true,
            searchedSN: '',
            Sub_Titles1:[
                {name: 'NBI Number', value:'test'},
                {name: 'Structure Name', value:'test'},
                {name: 'Feature Crossed', value:'test'},
                {name: 'Reference Location', value:'test'},
                {name: 'GeoLocation (Lat, Long)', value:'test'},
                {name: 'Skew Angle', value:'test'},
            ],
            Sub_Titles2:[],
            AllDataTitles:[],
            loading: true,
            found: false,
        }
    },
    methods:{
        renderMap(){
            const map = new google.maps.Map(document.getElementById('map'), {
                center: {lat: this.lat ,lng: this.lng},
                zoom: 17,
                mapTypeId: this.mapType, 
                maxZoom: 50,
                minZoom: 3,
                panControl:false,
                zoomControl:false,
                mapTypeControl:false,
                scaleControl:false,
                streetViewControl:false,
                overviewMapControl:false,
                rotateControl:false,
                fullscreenControl:false,
                styles:[{"elementType": "geometry","stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                    {
                        "visibility": "off"
                    }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#616161"
                    }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels",
                    "stylers": [
                    {
                        "visibility": "off"
                    }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text",
                    "stylers": [
                    {
                        "visibility": "off"
                    }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#757575"
                    }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#33d9b2"
                    },
                    {
                        "saturation": -30
                    },
                    {
                        "lightness": 55
                    }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text",
                    "stylers": [
                    {
                        "color": "#218c74"
                    },
                    {
                        "saturation": 100
                    },
                    {
                        "visibility": "on"
                    },
                    {
                        "weight": 0.5
                    }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#ffffff"
                    }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#757575"
                    },
                    {
                        "visibility": "on"
                    }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#dadada"
                    }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#616161"
                    }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels",
                    "stylers": [
                    {
                        "visibility": "off"
                    }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#9e9e9e"
                    },
                    {
                        "visibility": "on"
                    }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#34ace0"
                    },
                    {
                        "saturation": -45
                    },
                    {
                        "lightness": 25
                    },
                    {
                        "weight": 2
                    }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#227093"
                    },
                    {
                        "weight": 8
                    }
                    ]
                }]
            })
            
            let marker = new google.maps.Marker({
                position:{
                    lat: this.lat,
                    lng: this.lng
                },
                
                map
                
            })
            
        },

        learnMore(){
            this.show = !this.show
        },
        goToSearchPage(){
            this.$router.push({name:'SearchSN'})
        },
        openNewWindow(url){
            window.open(url, "_blank")
        },
        shareButtonPressed(){
            //Share the content
            this.router.url
            alert("Copied the text: " + cthis.router.url)
        },
        closeButtonSelected(){
            this.searchstarted = true
            this.errorPromptBool = false
            this.aboutPrompt = false
        },
        openAboutUsPage(){
            this.aboutPrompt = true
        },
        terrainSatelliteChange(){
            if (this.mapType == "satellite") {
                this.mapType = "terrain"
            } else {
                this.mapType = "satellite"
            }            
            this.renderMap()
        },
        loadData(){
            this.searchedSN = this.$route.params.sn
            //Check the length of the input
            this.tempSN = this.searchedSN
            while(this.tempSN.length<15){
                this.tempSN = this.tempSN + "0"
            }
            this.tempURL = 'https://geo.dot.gov/server/rest/services/Hosted/National_Bridge_Inventory_DS/FeatureServer/0/query?where=structure_number_008%20%3D%20'+"'"+this.tempSN+"'"+'&outFields=*&outSR=4326&f=json'
            axios.get(this.tempURL).then(response=>{
                //THE ERROR IS HERE
                if (response.data.features.length>0){
                    this.tempData = JSON.parse(JSON.stringify(response.data.features[0].attributes))
                    //Found data
                    this.lng = response.data.features[0].geometry.x
                    this.lat = response.data.features[0].geometry.y
                    this.loading = false
                    this.found = true
                    this.bridgeName = this.tempData.facility_carried_007 + ' over'
                    this.bridgeCross = this.tempData.features_desc_006a
                    this.bridgeSN = this.tempData.structure_number_008
                    this.Sub_Titles1[0].value = this.tempData.structure_number_008
                    this.Sub_Titles1[1].value = this.bridgeName
                    this.Sub_Titles1[2].value = 'over ' + this.bridgeCross
                    this.Sub_Titles1[3].value = this.tempData.location_009
                    this.Sub_Titles1[4].value = this.lat + ", " + this.lng
                    this.Sub_Titles1[5].value = this.tempData.degrees_skew_034 + " degrees"
                    //Check data for second tabel
                    if (this.tempData.culvert_cond_062 =! "N"){
                        //It's a culvert
                        this.Sub_Titles2 = [
                            {name: 'Structure Type', value:"Culvert"},
                            {name: 'Culvert Condition', value:this.tempData.culvert_cond_062},
                            {name: 'Superstructure Condition', value:this.tempData.superstructure_cond_059},
                            {name: 'Substructure Condition', value:this.tempData.substructure_cond_060}
                        ]
                    } else {
                        //It's a bridge
                        this.Sub_Titles2 = [
                            {name: 'Structure Type', value:"Bridge"},
                            {name: 'Deck Condition', value:this.tempData.deck_cond_058},
                            {name: 'Superstructure Condition', value:this.tempData.superstructure_cond_059},
                            {name: 'Substructure Condition', value:this.tempData.substructure_cond_060}
                        ]
                    }

                    //Fill up all data
                    this.AllDataTitles = [
                        {name: 'Year Built', value: this.tempData.year_built_027},
                        {name: 'Year Reconstructed', value: this.tempData.year_reconstructed_106},
                        {name: 'ADT Year', value: this.tempData.year_adt_030},
                        {name: 'ADT Value', value: this.tempData.adt_029},
                        {name: 'Future ADT Year', value: this.tempData.year_of_future_adt_115},
                        {name: 'Future ADT Value', value: this.tempData.future_adt_114},
                        {name: 'ADT Truck Percentage', value: this.tempData.percent_adt_truck_109 + "%"},
                        {name: 'Deck Width', value: this.tempData.deck_width_mt_052 + " ft"},
                        {name: 'Max Span Length', value: this.tempData.max_span_len_mt_048 + " ft"},
                        {name: 'Structure Length', value: this.tempData.structure_len_mt_049 + " ft"},
                    ]

                } else {
                    //Error - Could not find bridge
                    //Turn off sections (Desktop version)
                    this.loading = false
                    this.found = false
                    this.NoError = false
                }      
            })
            
        },
        openGoogelLink(){
            this.googleMapsLink = 'https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=' + this.lat + ',' + this.lng
            window.open(this.googleMapsLink, "_blank")
        },
        showHideAllData(){
            if (this.showAllData == true){
                this.dataButtonTitle = "MORE DATA"
                this.showAllData = false
            } else {
                this.dataButtonTitle = "LESS DATA"
                this.showAllData = true
            }
        }
    },
    beforeMount(){
        this.loadData()
    },
    mounted(){
        if (this.found) {
            this.renderMap()
        }
    },
    updated(){
        if(this.found){
            this.renderMap()
        }
    }
}
</script>

<style scoped>

.center{
    position: absolute;
    align-content: center;
    left: 40%;
    top: 20%;
}

</style>